import { Outlet } from "react-router-dom";
import Footer from "../Footer";
import Navbar from "../Navbar";

const DefaultLayout = () => {
  return (
    <main className="h-full min-h-screen w-screen flex flex-col justify-between relative overflow-x-hidden">
      <Navbar />
      <div className="flex-1">
        <Outlet />
      </div>
      <div className="mt-4">
        <Footer />
      </div>
    </main>
  );
};

export default DefaultLayout;
