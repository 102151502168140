import { Helmet } from "react-helmet-async";
import BureauContactButton from "../../components/BureauContactButton";
import BureauDoubleCardRow from "../../components/BureauDoubleCardRow";
import FAQ from "../../components/FAQ";
import usePageReset from "../../hooks/usePageReset";

const stats = [
  {
    number: "10,000+",
    description: "Hours of training for entrepreneurs, senior managers, and front line staff",
  },
  {
    number: "1500+",
    description: "Entrepreneurs Passed Our previous Programs",
  },
  {
    number: "25/75",
    description: "The Ratio of Theory and Practice",
  },
  {
    number: "350+",
    description: "We teach people from 4 continents and over 40 countries",
  },
];

const firstRow = [
  {
    src: "/images/bureau/bureau1.webp",
    alt: "bureau",
    description: "Guidance",
    hoverText:
      "Receive 300 minutes of expert mentorship and support from Cultiv Bureau's mentors and industry leaders.",
  },
  {
    src: "/images/bureau/bureau2.webp",
    alt: "bureau",
    description: "Market Research",
    hoverText:
      "Optimize your market research and strategy development with dedicated office hours and tailored workshops.",
  },
];

const secondRow = [
  {
    src: "/images/bureau/bureau3.webp",
    alt: "bureau",
    description: "Network",
    hoverText:
      "Connect with top industry leaders in Egypt to gain valuable insights and build relationships.",
  },
  {
    src: "/images/bureau/bureau4.webp",
    alt: "bureau",
    description: "Simplified Entry",
    hoverText:
      "Enjoy discounted access to Cultiv Bureau and ideaspace infrastructure, streamlining your operations and reducing soft-landing costs.",
  },
  {
    src: "/images/bureau/bureau5.webp",
    alt: "bureau",
    description: "Talent Pool",
    hoverText:
      "Access Egypt's highly skilled and multilingual talent through outsourcing, outstaffing, or dedicated project teams.",
  },
];

const faqsItems = [
  {
    question: "What is the Cultiv Bureau Soft-Landing Navigator program?",
    answer:
      "This free program helps companies determine the most effective soft-landing strategy for entering the Egyptian market. It provides guidance, support, and access to resources to ensure a smooth and successful transition.",
  },
  {
    question: "Who is this program for?",
    answer:
      "This program is ideal for companies of all sizes interested in expanding their business into Egypt. Whether you're a startup or an established company, the program can help you navigate the complexities of entering a new market.",
  },
  {
    question: "Is the program truly free?",
    answer:
      "Yes, the core elements of the program, including the initial consultation, assessment, and access to some resources, are completely free. We offer optional tiered structures with additional benefits at a cost.",
  },
  {
    question: "How do I enroll in the program?",
    answer: "Contact Cultiv Bureau today to discuss your needs and get started with the program.",
  },
  {
    question: "What happens after I complete the program?",
    answer:
      "The program provides you with the knowledge and resources to make informed decisions about your soft-landing strategy. Cultiv Bureau can also assist you with implementing your chosen approach and ongoing support in Egypt.",
  },
];

const Bureau = () => {
  const reset = usePageReset();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="keywords"
          content="outsourcing soft-landing business growth out-staffing tourism recruitment market-penetration"
        />
        <meta
          name="description"
          content="Cultiv Bureau: Expert outsourcing, out-staffing, and recruitment services tailored to boost your business efficiency and growth."
        />
        <meta name="robots" content="index, follow" />
        <title>Cultiv Bureau</title>
      </Helmet>

      <div className="flex flex-col gap-10 w-full">
        <section className="px-6 sm:px-20">
          <div className="flex flex-col  gap-2">
            <p className="sm:font-bold text-lg sm:text-2xl">Expanding your business to Egypt? </p>
            <p className="font-light sm:font-extralight text-sm sm:text-2xl">
              Cultiv Bureau is your one-stop shop for a seamless soft-landing. We bridge the gap
              between your global aspirations and the immense potential of the Egyptian market.
            </p>
          </div>
        </section>

        <section className="px-6 md:px-10 sm:px-20 py-8 bg-black">
          <div className="h-full grid grid-cols-2 place-items-center sm:grid-cols-none sm:grid-flow-col-dense gap-4 sm:divide-x-2 divide-[#D8D8D8]">
            {stats.map(({ number, description }, index) => {
              return (
                <div
                  key={index}
                  className={`flex flex-col items-center h-full w-full py-4 px-4 text-center ${
                    index > 1 && "border-t-2 border-[#D8D8D8] sm:border-t-0"
                  } items-center justify-center gap-3 `}
                >
                  <h6 className="sm:text-5xl text-xl font-bold text-gray-50">{number}</h6>
                  <p className="sm:text-base text-xs text-center text-white font-light max-w-[300px]">
                    {description}
                  </p>
                </div>
              );
            })}
          </div>
        </section>

        <section className="flex flex-col gap-10">
          <div className="flex flex-col gap-4 text-center px-5">
            <div className="flex flex-col gap-2 text-center">
              <h1 className="font-medium sm:text-[40px]">Unlock a World of Opportunity</h1>
              <p className="font-thin sm:text-3xl">with Cultiv Bureau</p>
            </div>
            <p className="font-light text-sm md:text-2xl !leading-6 sm:!leading-[50px]">
              This program is designed to help determine the ideal soft-landing strategy for your
              business in Egypt. This program is tailored to your unique needs and provides valuable
              resources to navigate the Egyptian market effectively.
            </p>
          </div>
          <BureauDoubleCardRow firstRow={firstRow} secondRow={secondRow} />
        </section>

        <section className="px-6 sm:px-20">
          <div className="flex flex-col gap-5 divide-y divide-black  ">
            {faqsItems.map(({ question, answer }, index) => {
              return <FAQ key={index} question={question} answer={answer} />;
            })}
          </div>
        </section>

        <section>
          <BureauContactButton />
        </section>
      </div>
    </>
  );
};

export default Bureau;
