import { Toaster } from "react-hot-toast";
import { Outlet, useNavigate } from "react-router";
import usePathname from "../../hooks/usePathname";
import { Tab } from "../../schema";
import BureauHeader from "../BureauHeader";
import Footer from "../Footer";
import Navbar from "../Navbar";

const bureauTabs: Tab[] = [
  {
    id: "",
    name: "What We Do",
  },
  {
    id: "/about",
    name: "About Bureau",
  },
  {
    id: "/services",
    name: "Our Services",
  },
  {
    id: "/pricing",
    name: "Pricing",
  },
  {
    id: "/contact",
    name: "Contact Us",
  },
];

const BureauLayout = () => {
  const navigate = useNavigate();
  const { subDirectory } = usePathname();

  return (
    <main className="h-full min-h-screen w-screen flex flex-col justify-between relative overflow-x-hidden">
      <Navbar />
      <BureauHeader />
      <div className="flex flex-col px-3 sm:px-5 md:px-20 py-10">
        <div className="border-b-2 border-gray-400 flex gap-7 p-2 w-full items-center justify-between sm:px-4 md:px-20">
          {bureauTabs.map(({ name, id }, index) => {
            return (
              <button
                key={index}
                className={`2xl:text-3xl lg:text-2xl md:text-xl sm:text-base text-xs text-gray-500 transition duration-500 hover:text-gray-900 ${
                  subDirectory === id.split("/")[1] && "!text-gray-900 font-semibold"
                } `}
                onClick={() => {
                  navigate(`/bureau${id}`);
                }}
              >
                {name}
              </button>
            );
          })}
        </div>
      </div>
      <div className="flex-1">
        <Outlet />
      </div>
      <Toaster />
      <div className="mt-4">
        <Footer />
      </div>
    </main>
  );
};

export default BureauLayout;
