export interface Props {
  title: string;
  feats: string[];
  price: string;
}

const BureauPricingCard = ({ feats, title, price }: Props) => {
  return (
    <div className="bg-white flex flex-col gap-4 p-4 md:p-8">
      <div className="flex items-center justify-center px-4 py-8 border-b-[3px] border-[#D9D9D9]">
        <h1 className="text-xl md:text-3xl font-bold">{title}</h1>
      </div>
      <ul className="flex flex-col md:flex-1 gap-4 px-8 md:pb-24 list-square">
        {feats.map((feat, index) => {
          return (
            <li key={index}>
              <p className="text-lg md:text-xl">{feat}</p>
            </li>
          );
        })}
      </ul>
      <div className="self-end w-full text-center sm:text-start items-center justify-center border-t-[3px] border-[#D9D9D9] py-4 md:py-8 px-4">
        <h6 className="text-3xl md:text-4xl font-medium">{`$${price}/month`}</h6>
      </div>
    </div>
  );
};

export default BureauPricingCard;
