import Image from "./Image";

const BureauHeader = () => {
  return (
    <div className="bg-black px-5 sm:px-20 py-13 flex flex-col lg:flex-row lg:justify-between gap-12 lg:gap-28 ">
      <div className="flex items-center justify-center">
        <Image src="/images/bureau/bureauWhiteLogo.svg" />
      </div>
      <div className="flex flex-col gap-5 lg:items-center justify-center text-center text-white">
        <h2 className="text-3xl lg:text-4xl font-medium ">Your Gateway to Success in Egypt</h2>
        <h3 className="font-thin leading-10">
          Leverage Egypt's Workforce, Infrastructure & Thriving Market
        </h3>
      </div>
    </div>
  );
};

export default BureauHeader;
