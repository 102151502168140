import { FormEvent, useState } from "react";
import toast from "react-hot-toast";
import usePageReset from "../../hooks/usePageReset";

type BureauFormState = {
  fName: string;
  email: string;
  number: string;
  jobTitle: string;
  industry: string;
  cName: string;
  comments: string;
  service: string;
};

type BureauFormStateError = {
  fName: boolean;
  email: boolean;
  number: boolean;
  jobTitle: boolean;
  reqService: boolean;
  industry: boolean;
  cName: boolean;
  service: boolean;
};

const sendEmail = async ({
  cName,
  comments,
  email,
  fName,
  industry,
  jobTitle,
  number,
  service,
}: BureauFormState) => {
  const formData = {
    service_id: import.meta.env.VITE_EMAILJS_SERVICE_ID,
    template_id: import.meta.env.VITE_EMAILJS_TEMPLATE_ID,
    user_id: import.meta.env.VITE_EMAILJS_PUBLIC_KEY,
    template_params: {
      name: fName,
      email: email,
      number: number,
      company_name: cName,
      job_title: jobTitle,
      industry: industry,
      message: comments,
      service_required: service,
    },
  };
  try {
    await fetch("https://api.emailjs.com/api/v1.0/email/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
  } catch (error) {
    console.log("Email sending failed:", error);
  }
};

const BureauContact = () => {
  const reset = usePageReset();

  const [formState, setFormState] = useState<BureauFormState>({
    fName: "",
    email: "",
    number: "",
    jobTitle: "",
    industry: "",
    cName: "",
    comments: "",
    service: "",
  });
  const [formStateError, setFormStateError] = useState<BureauFormStateError>({
    fName: false,
    email: false,
    number: false,
    jobTitle: false,
    reqService: false,
    industry: false,
    cName: false,
    service: false,
  });

  const handleChange =
    (fieldName: string) =>
    (e: FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      const { value } = e.currentTarget;
      setFormState((prevState) => {
        return {
          ...prevState,
          [fieldName]: value,
        };
      });
      if (formStateError[fieldName as keyof BureauFormStateError]) {
        setFormStateError((prevState) => {
          return {
            ...prevState,
            [fieldName]: false,
          };
        });
      }
    };

  const formSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const emptyInputs = Object.entries(formState).filter(
      ([key, val]) => key !== "comments" && !val
    );
    if (
      emptyInputs.length <= 0 &&
      !isNaN(Number(formState.number)) &&
      formState.number.length > 9
    ) {
      await sendEmail(formState);
      toast.success("We have received your email and will get back to you as soon as possible !", {
        duration: 5000,
      });
      setFormState({
        cName: "",
        comments: "",
        email: "",
        fName: "",
        industry: "",
        jobTitle: "",
        number: "",
        service: "",
      });
    } else {
      setFormStateError((prevState) => {
        return emptyInputs.reduce((acc, [key]) => {
          return {
            ...prevState,
            ...acc,
            [key]: true,
          };
        }, {} as BureauFormStateError);
      });
    }
    if (isNaN(Number(formState.number)) || formState.number.length > 9) {
      setFormStateError((pervState) => {
        return {
          ...pervState,
          number: true,
        };
      });
    }
  };

  return (
    <div className="flex flex-col gap-10 items-center justify-center px-6 md:px-20 2xl:px-48">
      <p className="font-light sm:font-semibold text-center text-xl sm:text-2xl lg:text-4xl">
        For more Information Please Contact us
      </p>
      <form className="flex flex-col gap-10 w-full" onSubmit={formSubmit}>
        <div className="flex flex-col gap-3 sm:gap-5">
          <div className="flex flex-col md:flex-row gap-3 sm:gap-13 w-full">
            <div id="form-name" className="flex flex-col gap-2 w-full md:w-1/2">
              <label htmlFor="full-name">Full Name :</label>
              <input
                type="text"
                id="full-name"
                name="full_name"
                placeholder="John Doe"
                className={`border-2 ${
                  formStateError.fName ? "!border-red-600" : "border-[#CBD2E0]"
                } py-1 px-2`}
                value={formState.fName}
                onChange={handleChange("fName")}
              />
              {formStateError.fName && (
                <p className="text-red-600 text-xs font-bold">This field is required</p>
              )}
            </div>
            <div id="form-email" className="flex flex-col gap-2 w-full md:w-1/2">
              <label htmlFor="email">Email :</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="example@email.com"
                className={`border-2 border-[#CBD2E0] ${
                  formStateError.email ? "!border-red-600" : "border-[#CBD2E0]"
                } py-1 px-2`}
                value={formState.email}
                onChange={handleChange("email")}
              />
              {formStateError.email && (
                <p className="text-red-600 text-xs font-bold">This field is required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-3 sm:gap-13 w-full">
            <div id="form-number" className="flex flex-col gap-2 w-full md:w-1/2">
              <label htmlFor="number">Number :</label>
              <input
                type="text"
                id="number"
                name="number"
                placeholder="+1(123)456-789"
                className={`border-2 border-[#CBD2E0] ${
                  formStateError.number ? "!border-red-600" : "border-[#CBD2E0]"
                } py-1 px-2`}
                value={formState.number}
                onChange={handleChange("number")}
              />
              {formStateError.number && (
                <p className="text-red-600 text-xs font-bold">
                  This field is required or This is not a phone number
                </p>
              )}
            </div>
            <div id="form-industry" className="flex flex-col gap-2 w-full md:w-1/2">
              <label htmlFor="industry">Industry :</label>
              <input
                type="text"
                id="industry"
                name="industry"
                placeholder="ex: Marketing"
                className={`border-2 border-[#CBD2E0] ${
                  formStateError.industry ? "!border-red-600" : "border-[#CBD2E0]"
                } py-1 px-2`}
                value={formState.industry}
                onChange={handleChange("industry")}
              />
              {formStateError.industry && (
                <p className="text-red-600 text-xs font-bold">This field is required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-3 sm:gap-13 w-full">
            <div id="form-job" className="flex flex-col gap-2 w-full md:w-1/2">
              <label htmlFor="job-title">Job Title :</label>
              <input
                type="text"
                id="job-title"
                name="job_title"
                placeholder="ex: Business Analyst"
                className={`border-2 border-[#CBD2E0] ${
                  formStateError.jobTitle ? "!border-red-600" : "border-[#CBD2E0]"
                } py-1 px-2`}
                value={formState.jobTitle}
                onChange={handleChange("jobTitle")}
              />
              {formStateError.jobTitle && (
                <p className="text-red-600 text-xs font-bold">This field is required</p>
              )}
            </div>
            <div id="form-company" className="flex flex-col gap-2 w-full md:w-1/2">
              <label htmlFor="job-title">Company Name :</label>
              <input
                type="text"
                id="company-name"
                name="company_name"
                placeholder="ex: Cultiv LLC"
                className={`border-2 border-[#CBD2E0] ${
                  formStateError.cName ? "!border-red-600" : "border-[#CBD2E0]"
                } py-1 px-2`}
                value={formState.cName}
                onChange={handleChange("cName")}
              />
              {formStateError.cName && (
                <p className="text-red-600 text-xs font-bold">This field is required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-3 sm:gap-13 w-full">
            <div id="form-service" className="flex flex-col gap-2 w-full md:w-1/2">
              <label htmlFor="service-required">Service Required :</label>
              <select
                id="service"
                name="service"
                placeholder={formState.service}
                className={`border-2 border-[#CBD2E0] ${
                  formStateError.service ? "!border-red-600" : "border-[#CBD2E0]"
                } py-1 px-2`}
                value={formState.service}
                onChange={handleChange("service")}
              >
                <option value="Out-Staffing">Out-Staffing</option>
                <option value="Service Outsourcing">Service Outsourcing</option>
                <option value="Project OutSourcing">Project OutSourcing</option>
              </select>
              {formStateError.service && (
                <p className="text-red-600 text-xs font-bold">This field is required</p>
              )}
            </div>
            <div id="form-info" className="flex flex-col gap-2 w-full md:w-1/2">
              <label htmlFor="comments">Additional Information :</label>
              <textarea
                id="comments"
                name="comments"
                placeholder="Enter any additional information you would like to share or can be useful to us here."
                className="border-2 border-[#CBD2E0] py-1 px-2 w-full h-32"
                value={formState.comments}
                onChange={handleChange("comments")}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <button
            type="submit"
            className="px-20 py-2 text-white bg-[#1E1E1E] hover:bg-cultiv-yellow hover:text-black"
          >
            <p className="font-semibold text-2xl ">Submit</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default BureauContact;
