import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import { panelArr } from "../assets/cultivatorsEventPanels";
import CardsGrid from "../components/CardsGrid";
import GridController from "../components/GridController";
import MarkDown from "../components/MarkDown";
import useEventSeries from "../hooks/useEventSeries";
import { Tab } from "../schema";
import Loading from "../components/Loading";
import usePathname from "../hooks/usePathname";

const EventSeries = () => {
  const { eventSeriesID } = useParams();
  const eventSeriesTabs: Tab[] = [
    { name: "Overview", id: eventSeriesID || "" },
    { name: "Panels", id: eventSeriesID + "/panels" },
  ];
  const { thirdDirectory } = usePathname();

  const [displayed, setDisplayed] = useState<string>(
    thirdDirectory ? eventSeriesTabs[1].id : eventSeriesTabs[0].id
  );

  const { series, isLoading } = useEventSeries(eventSeriesID || "");

  const setTab = (id: string) => {
    setDisplayed(id);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="keywords"
          content="event event-series cultivators lisbon cultiv portugal Forum"
        />
        <meta name="description" content="Event-series that is organized and funded by cultiv" />
        <meta name="robots" content="index, follow" />
        <title>{series?.title}</title>
      </Helmet>
      {isLoading ? (
        <div className="flex justify-center items-center h-0 min-h-screen">
          <Loading />
        </div>
      ) : (
        <div className="w-full flex flex-col gap-5 justify-center items-center">
          <img
            className="object-cover w-full"
            src={"/images/cultivators/event-series-cover.svg"}
            alt="Cultivators Cover"
          />

          <div className="pl-6 w-full flex flex-col gap-4 px-10">
            <div className="w-full h-full ">
              <GridController
                tabs={eventSeriesTabs}
                displayed={displayed}
                setDisplayed={setTab}
                page="culture/event-series"
              />
            </div>
            {displayed === eventSeriesID + "/panels" ? (
              <div>
                <CardsGrid cards={panelArr} />
              </div>
            ) : (
              <div className="flex flex-col w-full gap-16">
                <MarkDown
                  proseHeaders="prose-headings:m-3"
                  proseLead="prose-lead:leading-none"
                  proseList="prose-ul:list-disc"
                  prosePadding="pr-8"
                  proseParagraph="prose-p:my-2"
                  articleBody={series?.md_text as string}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EventSeries;
