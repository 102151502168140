import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import CultivatorsImageModal from "../components/CultivatorsImageModal";
import GridController from "../components/GridController";
import Image from "../components/Image";
import Loading from "../components/Loading";
import MarkDown from "../components/MarkDown";
import PersonCard from "../components/PersonCard";
import useEvent from "../hooks/useEvent";
import usePathname from "../hooks/usePathname";
import { open } from "../redux/features/cultivatorsImageModalSlice";
import { Tab } from "../schema";

const Event = () => {
  const { eventID } = useParams();
  const { thirdDirectory } = usePathname();
  const [postData, isLoading] = useEvent(eventID as string);
  const dispatch = useDispatch();
  const openModal = (img: string) => {
    dispatch(open(img));
  };

  const eventTabs: Tab[] = [
    { name: "Overview", id: eventID || "" },
    { name: "Gallery", id: eventID + "/gallery" },
  ];
  const [displayed, setDisplayed] = useState<string>(
    thirdDirectory ? eventTabs[1].id : eventTabs[0].id
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const eventDate = new Date(postData?.post_meta?.start_date as string);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content={`${postData?.title} event post panel cultiv`} />
        <meta name="description" content={`This is an event about ${postData?.title}`} />
        <meta name="robots" content="index, follow" />
        <title>Cultiv | {`${postData ? postData.title : "Event"}`}</title>
      </Helmet>
      {isLoading ? (
        <div className="flex justify-center items-center h-0 min-h-screen">
          <Loading />
        </div>
      ) : (
        <div>
          {postData?.gallery && (
            <Image
              src={postData?.post_cover}
              height={500}
              className=" object-cover w-full"
              alt={postData?.title}
            />
          )}
          <div className={`w-full flex flex-col justify-center items-center gap-14 pt-8`}>
            {postData?.gallery && (
              <div className="pl-6 w-full flex flex-col gap-4">
                <div className="w-full h-full pr-6">
                  <GridController
                    tabs={eventTabs}
                    displayed={displayed}
                    setDisplayed={setDisplayed}
                    page="culture/event"
                  />
                </div>
              </div>
            )}
          </div>
          {displayed === postData?.title_slug + "/gallery" ? (
            <div className="pl-6 w-full flex flex-col gap-4 py-5">
              <div
                className={`grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-y-8 w-full place-items-center gap-x-8 pr-6`}
              >
                {postData?.gallery?.map((image, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => {
                        openModal(index.toString() || "");
                      }}
                      className="cursor-pointer w-full h-full"
                    >
                      <Image src={image} alt={`Gallery Image ${index}`} />
                    </button>
                  );
                })}
              </div>
              {postData.gallery && <CultivatorsImageModal gallery={postData.gallery} />}
            </div>
          ) : (
            <div className="w-full flex flex-col justify-center items-center gap-10 px-8 mt-8">
              <div
                className={`flex flex-col w-full justify-center ${
                  postData?.gallery ? "" : "pb-8 border-b-2 border-gray-400 items-center"
                }`}
              >
                <h1 className="text-4xl font-bold">{postData?.title}</h1>
                <p className="text-xl text-gray-500 pt-3">
                  {eventDate && eventDate.toDateString()}, {postData?.post_meta?.location}
                </p>
                <p className="text-xl text-gray-500 pt-3">
                  {postData?.post_meta.author && `, By ${postData?.post_meta?.author}`}
                </p>
              </div>
              {!postData?.gallery && (
                <Image
                  src={postData?.post_cover}
                  width={1000}
                  height={500}
                  className=" object-cover"
                  alt={postData?.title}
                />
              )}
              <MarkDown articleBody={postData?.md_text} />

              {postData?.panelists && (
                <div className="flex flex-col w-full gap-16">
                  <h1 className="sm:text-4xl text-2xl font-bold">Meet The Panelists</h1>

                  <div
                    className={`grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-y-8 w-full place-items-center gap-x-8 pr-6 sm:pr-12`}
                  >
                    {postData.panelists.map((panelist) => {
                      return <PersonCard key={panelist.id} person={panelist} panelist={true} />;
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Event;
