import BureauDoubleCardRow from "../../components/BureauDoubleCardRow";
import usePageReset from "../../hooks/usePageReset";

const firstRow = [
  {
    src: "/images/bureau/para.svg",
    alt: "bureau",
    description: "Soft-Landing",
    hoverText:
      "Cultiv Bureau provides comprehensive support to simplify your entry and ensure a smooth transition into the Egyptian market.",
  },
  {
    src: "/images/bureau/people.svg",
    alt: "bureau",
    description: "Outsourcing",
    hoverText: "Delegate routine tasks and access a cost-effective workforce in Egypt.",
  },
];

const secondRow = [
  {
    src: "/images/bureau/people-gear.svg",
    alt: "bureau",
    description: "Out-Staffing",
    hoverText:
      "Supplement your team with temporary or permanent Egyptian talent for specific needs.",
  },
  {
    src: "/images/bureau/puzzle.svg",
    alt: "bureau",
    description: "Project Teams",
    hoverText:
      "Get expert project based assistance for specific projects with our skilled Egyptian professionals.",
  },
  {
    src: "/images/bureau/magnifying-glass.svg",
    alt: "bureau",
    description: "Market Research",
    hoverText:
      "Gain valuable insights into the Egyptian market and your target audience through our tailored research solutions.",
  },
];

const Services = () => {
  const reset = usePageReset();
  return (
    <div className="flex flex-col gap-10 ">
      <section className="flex flex-col gap-10 px-4 sm:px-20">
        <div className="flex flex-col gap-4 px-5">
          <p className="font-light text-sm sm:text-base md:text-2xl !leading-6 lg:!leading-[50px]">
            Cultiv Bureau offers a wide range of services tailored to meet the diverse needs of
            startups and SMEs. Our core offerings include outsourcing, out-staffing, recruitment,
            technology resale, and consulting services. By leveraging our extensive network and
            expertise, we ensure that your business operations run smoothly and efficiently,
            allowing you to focus on growth and innovation.
          </p>
        </div>
      </section>
      <section className="flex flex-col gap-10">
        <BureauDoubleCardRow firstRow={firstRow} secondRow={secondRow} />
      </section>
    </div>
  );
};

export default Services;
