import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import CardsGrid from "../components/CardsGrid";
import Loading from "../components/Loading";
import MemberPopup from "../components/MemberPopup";
import MembersButtonsGrid from "../components/MembersButtonsGrid";
import ShowMoreBtn from "../components/ShowMoreBtn";
import Slider from "../components/Slider";
import useHomeFeed from "../hooks/useHomeFeed";
import usePageReset from "../hooks/usePageReset";
import { RootState } from "../redux/configureStore";
import {
  getButtonStatus,
  getCurrentPage,
  reset,
  setMaxPage,
} from "../redux/features/pagePaginationSlice";

export default function Home() {
  const openMemberModal = useSelector((state: RootState) => state.memberModal.open);
  const currentPage = useSelector((state: RootState) =>
    getCurrentPage(state.pagePagination, "home")
  );
  const [posts, isLoading, lastPage] = useHomeFeed(currentPage);
  const showMoreButton = useSelector((state: RootState) =>
    getButtonStatus(state.pagePagination, "home")
  );
  const dispatch = useDispatch();
  const cleanPage = () => {
    dispatch(reset("home"));
  };

  const pageReset = usePageReset();

  useEffect(() => {
    dispatch(setMaxPage({ id: "home", maxPage: lastPage }));
    return () => cleanPage();
  }, [lastPage]);

  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="cultiv entrepreneur startup members collective company consultation business growth idea"
        />
        <meta
          name="description"
          content="Cultiv LLC is an Egyptian company that consist of a collective of companies, Cultiv has been a pioneer in the ecosystem of the Egyptian market since 2011"
        />
        <meta name="robots" content="index, follow" />
        <title>Cultiv</title>
      </Helmet>
      <div className="w-full flex flex-col justify-center gap-5 px-8 mt-8">
        <div className="w-full grid lg:grid-cols-3 grid-cols-1 gap-3 ">
          <Slider />
          <div className="flex flex-col gap-4 col-span-1 w-full">
            <h1 className="2xl:text-2xl md:text-xl text-sm  border-b-2 border-gray-400 w-full text-start pb-2">
              Collective Members
            </h1>
            <MembersButtonsGrid />
          </div>
        </div>
        {openMemberModal && <MemberPopup />}
        <div className="w-full flex flex-col gap-5 justify-center items-center ">
          {isLoading ? <Loading /> : <CardsGrid cards={posts} />}
          {showMoreButton && <ShowMoreBtn pageId="home" />}
        </div>
      </div>
    </>
  );
}
