import { Helmet } from "react-helmet-async";
import AboutPageIcon from "../components/Icons/AboutPageIcon";
import usePageReset from "../hooks/usePageReset";

export default function About() {
  const pageReset = usePageReset();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="cultiv" />
        <meta name="description" content="Know all about Cultiv LLC" />
        <meta name="robots" content="index, follow" />
        <title>About Cultiv</title>
      </Helmet>
      <div className="w-full">
        <AboutPageIcon />
      </div>
      <div className="flex flex-col px-8">
        <div className="flex flex-col justify-center items-center gap-4 ">
          <div className="flex flex-col gap-4 justify-start items-start py-2 leading-9">
            <h1 className="text-gray-900 md:text-2xl text-xl font-bold  text-center mt-5">
              Who are we ?
            </h1>
            <p className="text-gray-800 lg:text-xl lg:leading-9 text-left md:text-lg text-sm md:leading-9	">
              The Cultiv Collective represents a group of interdependent organizations providing a
              diverse set of activities within the field of innovation and entrepreneurship. Our
              mission is to empower entrepreneurs, innovators, technologists and designers in Egypt
              and the MENA region, with access to surrounding regions globally, primarily Europe and
              Africa. We do this through the continuous development of a global network of experts,
              professionals and investors. Our members operate in a number of activity areas,
              including venture building, technology product development, incubation/acceleration of
              startups, international technology and innovation competitions, venture capital,
              turnkey business solutions for SMEs, global market access, and operating co-working
              and innovation spaces. We are connected with an extensive network of clusters, tech
              hubs, universities, government agencies and other organizations in the areas of
              deep-tech and ICT, in Europe and the MENA region, with a special focus in the areas of
              AI, software, design, fintech, semiconductors, electronics, hardware, biotech,
              cybersecurity, digital transformation, agri-tech, cleantech, climate and travel.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
