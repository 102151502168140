import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { panelArr } from "../../assets/cultivatorsEventPanels";
import structuredData from "../../assets/structuredText";
import CardsGrid from "../../components/CardsGrid";
import MarqueeText from "../../components/MarqueeText";
import usePageReset from "../../hooks/usePageReset";

const CultivatorsHome = () => {
  const pageReset = usePageReset();
  const renderMarqueeText = (count: number) => {
    return Array.from({ length: count }, (_, index) => <MarqueeText key={index} />);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="keywords"
          content="cultiv cultivators entrepreneur startup members collective company consultation networking mentorship business growth idea network global collaboration events"
        />
        <meta
          name="description"
          content="Cultivators, a community-based series of events to empower individuals around the globe to positively impact the future through cross-border networking, knowledge sharing, and mentorship."
        />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">{structuredData}</script>
        <title>Cultivators</title>
      </Helmet>
      <div className="flex flex-col">
        <section className="w-full flex justify-center items-center gap-8 md:px-8 px-3 md:pt-20 md:pb-14 py-4">
          <div className="flex flex-col md:text-3xl lg:text-4xl text-sm text-center items-center justify-center md:px-10 px-0">
            <p className="pb-2.5 xl:leading-[50px]">
              The Global Discussion Panel - Investing in Commercializing Universities R&D
            </p>
            <p className="font-extralight md:text-2xl text-xs md:pt-7 pt-0">Jan 15, 2024</p>
          </div>
        </section>

        <section className="bg-black text-white flex flex-col items-center justify-center">
          <h2 className="md:text-5xl lg:text-6xl text-xl font-medium md:py-10 py-3">
            Our Biggest Forum Yet
          </h2>
          <div className="md:flex md:px-5 xl:px-10 px-4 md:pb-5 lg:pb-16 pb-3 h-full">
            <img
              className="md:px-0 md:max-w-md xl:max-w-max xl:px-10"
              src="/images/cultivators/world-photo.svg"
              alt="world-image"
            />
            <div className="flex">
              <p className="md:text-base md:leading-5 lg:tracking-wider xl:tracking-normal lg:text-xl xl:text-2xl text-xs flex-grow lg:leading-9 xl:leading-10 leading-5 tracking-wider md:pl-5 md:pr-3 xl:pr-10 md:pt-5 pt-1 sm:text-justify md:text-justify lg:text-justify">
                We're going global! This year's Cultivators Forum focused on empowering individuals
                around the globe to positively impact the future through cross-border networking,
                knowledge sharing, and mentorship. Get the latest industry insights, mingle with
                fellow practitioners, and get inspired by our keynote speakers during this exciting
                forum.
              </p>
            </div>
          </div>
        </section>

        <section className="flex md:py-14 py-5 xl:px-20 xl:pr-0 px-6 items-center justify-center">
          <CardsGrid cards={panelArr} />
        </section>

        <section className="bg-black text-white flex flex-col items-center justify-center">
          <h2 className="md:text-5xl lg:text-6xl text-base md:py-10 pt-5 pb-2.5 text-center">
            Ready To Attend The Next Forum?
          </h2>
          <p className="md:text-3xl lg:text-4xl text-xs font-extralight">
            Let's meet in the 3rd Panel Discussion
          </p>
          <div className="flex md:gap-10 lg:gap-48 gap-6 md:text-2xl font-semibold text-sm md:pt-14 md:pb-10 py-5">
            <Link
              to={"/cultivators/register"}
              className="bg-black text-white ease-in-out duration-300 md:w-60 w-28 h-12 border-2 hover:bg-cultiv-yellow hover:border-cultiv-yellow hover:text-black flex items-center justify-center"
            >
              Join Us
            </Link>
            <Link
              to={"/cult/cultivators"}
              className="bg-black text-white ease-in-out duration-300 md:w-64 w-44 h-12 border-2 hover:bg-cultiv-yellow hover:border-cultiv-yellow hover:text-black flex items-center justify-center"
            >
              Meet Our Network
            </Link>
          </div>
          <div className="w-full border-y-2 md:mb-10 mb-5 overflow-hidden md:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)] [mask-image:_linear-gradient(to_right,transparent_0,_black_70px,_black_calc(100%-70px),transparent_100%)]">
            <div className="relative flex overflow-x-hidden overflow-y-hidden">
              <div className="animate-marquee whitespace-nowrap text-[0] leading-[0] flex items-center">
                {renderMarqueeText(15)}
              </div>
              <div className="absolute flex animate-marquee2 whitespace-nowrap text-[0] leading-[0] items-center">
                {renderMarqueeText(15)}
              </div>
            </div>
          </div>
        </section>

        <section className="flex flex-col gap-5 h-full md:pl-10 lg:px-14 xl:px-28 2xl:px-36 px-7 md:pt-12 pt-4 md:pb-10 lg:pb-20 pb-2.5">
          <div className="flex md:flex-row flex-col justify-between">
            <div className="flex flex-col flex-grow md:gap-10 gap-1">
              <div className="max-w-fit">
                <p className="md:text-3xl lg:text-4xl text-sm font-light">Upcoming Webinar</p>
              </div>
              <div className="flex flex-col flex-1 md:gap-10 gap-2">
                <div className="md:max-w-fit md:self-start">
                  <p className="md:text-5xl lg:text-6xl sm:text-center lg:text-start text-center text-base py-2 font-medium font-mono flex flex-col items-center md:items-start lg:leading-[70px]">
                    Uncovering Profitable Opportunities in
                    <span className="max-w-fit"> Early-Stage Startups</span>
                  </p>
                </div>
                <div className="flex md:flex-row flex-col md:gap-4 lg:gap-28 gap-2 md:text-xl text-[10px] md:py-5">
                  <p>
                    <span className="font-semibold md:text-2xl text-xs lg:pr-2.5 pr-1">Date:</span>
                    Sunday 21st of March 2025
                  </p>
                  <p>
                    <span className="font-semibold md:text-2xl text-xs lg:pr-2.5 pr-1">Time:</span>
                    10:00 AM to 12:00 PM
                  </p>
                </div>
              </div>
            </div>
            <div className=" flex md:flex-col min-w-max items-center md:gap-14 gap-10 pt-6">
              <div className="rounded-full overflow-hidden md:w-48 w-28">
                <img
                  src="/images/cultivators/paulo-santos.svg"
                  alt="paulo-santos"
                  height={200}
                  width={200}
                />
              </div>
              <div className="border-2 flex md:text-xl text-[10px] flex-col items-center md:gap-2 md:px-5 px-3 pt-2">
                <p className="md:text-2xl text-xs">
                  <span className="font-normal">Speaker:</span> Paulo Santos
                </p>
                <p className="font-light md:text-xl text-xs md:pt-0 pt-1.5">IPN Incubadora</p>
                <p className="font-extralight flex flex-col text-center md:leading-8">
                  <span>Executive Director|</span>
                  <span>Investments| IPN| University</span>
                  <span>of Coimbra</span>
                </p>
              </div>
            </div>
          </div>
          <p className="md:text-2xl text-xs md:leading-[50px] leading-5">
            Explore its transformative power as a gateway to profitable ventures. Learn to identify
            and nurture startups embracing inclusivity, crafting portfolios that are both diverse
            and innovative. Walk away equipped with actionable strategies to make investments that
            leave a mark - driving societal change and financial returns in tandem.
          </p>
        </section>
      </div>
    </>
  );
};

export default CultivatorsHome;
