import { CultivContact, FooterRedirectLink } from "../schema";

export const contacts: CultivContact[] = [
  { contact: "124 Othman Ibn Afan Street Heliopolis, Cairo 11361, Egypt" },
  { contact: "16192 Coastal Highway Lewes DE 19958, USA" },
  { contact: "info@cultiv.llc" },
  { contact: "+20-2-2774-4374" },
];

export const footerLinks: FooterRedirectLink[] = [
  { title: "About", pathname: "/about" },
  { title: "Careers", pathname: "/careers" },
];
