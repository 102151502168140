import usePageReset from "../../hooks/usePageReset";

const AboutBureau = () => {
  const reset = usePageReset();

  return (
    <div className="flex flex-col gap-10 w-full px-6 lg:px-20 ">
      <div className="text-sm sm:text-base lg:text-2xl font-light !leading-6 sm:!leading-7 lg:!leading-[50px] ">
        <p>
          Cultiv Bureau is part of the Cultiv Collective, a dynamic ecosystem of interconnected
          companies fostering innovation across entrepreneurship and beyond. Established in 2019,
          Cultiv is not just a single entity; it's a collaborative force empowering individuals and
          businesses to thrive.
        </p>
      </div>
      <div className="w-full flex flex-col gap-5">
        <h1 className="text-lg md:text-xl lg:text-3xl sm:font-bold ">
          Cultivating Your Success in Egypt
        </h1>
        <div className="text-sm sm:text-base lg:text-2xl font-light !leading-6 sm:!leading-7 lg:!leading-[50px] ">
          <p>
            At Cultiv Bureau, we leverage the expertise of the Cultiv Collective to provide you with
            a comprehensive soft-landing strategy for the Egyptian market. We understand the unique
            challenges and opportunities Egypt presents, and we're here to guide you every step of
            the way.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col gap-5">
        <h1 className=" sm:text-lg md:text-xl lg:text-3xl sm:font-bold ">
          Here's what sets Cultiv Bureau apart
        </h1>
        <ul className="list-square flex flex-col gap-4 px-6">
          <li>
            <div className="font-light sm:font-normal text-xs sm:text-base !leading-5 lg:text-2xl sm:!leading-7 lg:!leading-[50px] ">
              <p>
                Empowering the MENA Region: Cultiv's core mission is to cultivate people, ideas, and
                knowledge across the Middle East and North Africa (MENA) region. We believe in
                empowering entrepreneurs, innovators, and creative minds to achieve their full
                potential.
              </p>
            </div>
          </li>
          <li>
            <div className="font-light sm:font-normal text-xs sm:text-base !leading-5 lg:text-2xl sm:!leading-7 lg:!leading-[50px] ">
              <p>
                Proven Track Record in Innovation: Cultiv's collective expertise spans incubator and
                accelerator programs, capacity building sessions, and access to innovative
                workspaces. We have a proven track record of nurturing businesses from ideation to
                success.
              </p>
            </div>
          </li>
          <li>
            <div className="font-light sm:font-normal text-xs sm:text-base !leading-5 lg:text-2xl sm:!leading-7 lg:!leading-[50px]">
              <p>
                Meticulous Project Management: We meticulously manage your soft-landing in its early
                stages, paying close attention to every detail. We provide comprehensive business
                support services, including mentorship, training, office facilities, and potential
                access to seed funding through Cultiv's accelerator program.
              </p>
            </div>
          </li>
          <li>
            <div className="font-light sm:font-normal text-xs sm:text-base !leading-5 lg:text-2xl sm:!leading-7 lg:!leading-[50px] ">
              <p>
                Meticulous Project Management: We meticulously manage your soft-landing in its early
                stages, paying close attention to every detail. We provide comprehensive business
                support services, including mentorship, training, office facilities, and potential
                access to seed funding through Cultiv's accelerator program.
              </p>
            </div>
          </li>
          <li>
            <div className="font-light sm:font-normal text-xs sm:text-base !leading-5 lg:text-2xl sm:!leading-7 lg:!leading-[50px] ">
              <p>
                Connecting You to a Network of Experts: Cultiv's vast network opens doors to
                valuable partnerships, investors, and potential clients within the Egyptian market.
                Through Cultiv Bureau, you gain a foothold in a dynamic ecosystem designed for your
                success.
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div className="w-full flex flex-col gap-5">
        <h1 className="sm:text-lg md:text-xl lg:text-3xl sm:font-bold ">
          Ready to cultivate your success in Egypt?
        </h1>
        <div className="text-xs sm:text-base !leading-4 lg:text-2xl sm:!leading-7 lg:!leading-[50px] ">
          <p>
            Contact Cultiv Bureau today and discover how our tailored soft-landing strategies can
            help you thrive in this dynamic market.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutBureau;
