import { BureauRow } from "../schema";
import BureauImageCard from "./BureauImageCard";

export interface Props {
  firstRow: BureauRow[];
  secondRow: BureauRow[];
}

const BureauDoubleCardRow = ({ firstRow, secondRow }: Props) => {
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:flex xl:items-center xl:justify-center place-items-center items-center gap-8 px-6 md:px-20 xl:px-32 2xl:px-48 min-h-[100px]">
        {firstRow.map(({ src, alt, description, hoverText }, index) => {
          return (
            <BureauImageCard
              key={index}
              imgSrc={src}
              imgAlt={alt}
              cardText={description}
              hoverText={hoverText}
            />
          );
        })}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center xl:flex xl:items-center xl:justify-center gap-y-8 gap-8 px-6 md:px-20 xl:px-10  min-h-max">
        {secondRow.map(({ src, alt, description, hoverText }, index) => {
          return (
            <BureauImageCard
              key={index}
              imgSrc={src}
              imgAlt={alt}
              cardText={description}
              hoverText={hoverText}
            />
          );
        })}
      </div>
    </>
  );
};

export default BureauDoubleCardRow;
