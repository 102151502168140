import { useNavigate } from "react-router";

export default function JoinCultivators() {
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col justify-center gap-2 relative w-full h-full border-[1px] border-solid border-black`}
    >
      <div className="relative  w-full flex justify-center items-center  overflow-hidden">
        <button onClick={() => navigate("/cultivators/register")} className="w-full h-full">
          <img
            src="/images/becomeACultivator.svg"
            alt="Join the cultivators"
            className="w-full h-full transition-all transform ease-in-out duration-[800ms] hover:scale-110"
          />
        </button>
      </div>
      <div className="flex justify-center items-center">
        <button onClick={() => navigate("/cultivators/register")}>
          <h2 className="text-gray-900 2xl:text-2xl xl:text-lg md:text-sm text-xs">
            Become A Cultivator
          </h2>
        </button>
      </div>
    </div>
  );
}
